.ScrollDark {
  width: 100%;
  left: 0;
  height: 75px;
  top: 0px;
  position: fixed;
  transition: all ease-in-out 0.6s;
  background-color: #000821;
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
  backdrop-filter: saturate(180%) blur(50px);
}

.ScrollLight {
  width: 100%;
  left: 0;
  top: 0px;
  position: fixed;
  height: 75px;
  transition: all ease-in-out 0.6s;
}

.InnerBg {
  background-color: #fff;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 200px;
  background-position: top center;
  background-size: cover;
  display: flex;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 30px;
}

.FontDark {
  color: #fff;
  white-space: nowrap;
}

.FontLight {
  color: #000821;
  white-space: nowrap;
}

.SearchBoxNav {
  max-width: 300px;
  width: 100%;
  margin-left: auto;
}

.SearchScrollIcon .FontDark {
  color: #fff;

}

.SearchScrollIcon .FontLight {
  color: #000821;
}

.ScrollDark button[aria-expanded="true"] span svg {
  transform: rotate(180deg);
  transition: all ease-in-out 0.5s;
}

.ScrollDark button[aria-expanded="false"] span svg {
  transform: rotate(0deg);
  transition: all ease-in-out 0.5s;
}

.ScrollLight button[aria-expanded="true"] span svg {
  transform: rotate(180deg);
  transition: all ease-in-out 0.5s;
}

.ScrollLight button[aria-expanded="false"] span svg {
  transform: rotate(0deg);
  transition: all ease-in-out 0.5s;
}

.activeMenuButton {
  font-weight: 500 !important;
  height: 32px !important;
  border: 1px solid #f05605;
  color: #fff;
  background-color: #f05605 !important;
  font-size: 0.775rem !important;
  transition: all 0.6s;
}

.activeMenuButton:active {
  background: #f05605;
  color: #fff;
  transition: 0.6s;
}

.activeMenuButton:hover {
  background: #f05605;
  color: #fff;
  transition: 0.6s;
}

.inactiveMenuButton {
  font-weight: 500 !important;
  height: 32px !important;
  border: 1px solid #f05605;
  color: #f05605;
  background-color: #fff !important;
  transition: all 0.6s;
  font-size: 0.775rem !important;
}

.inactiveMenuButton:active {
  background: #f05605;
  color: #fff;
  transition: 0.6s;
}

.inactiveMenuButton:hover {
  background: #f05605 !important;
  color: #fff;
  transition: 0.6s;
}
.MenuSubText {
  display: flex;
  flex-wrap: wrap;
  word-break: unset;
  overflow-wrap: unset;
  max-width: 210px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 15px;
  margin-top: -12px;
}
.MenuNestText {
  font-size: 14px;
  color: #677788;
  font-weight: 600;
  margin-left: 15px;
}
.MenuSubText span:first-child {
  display: none;
}